import React, { useEffect, useState } from "react";

const OurImpact = () => {
  const [satisfaction, setSatisfaction] = useState(0);
  const [experience, setExperience] = useState(0);
  const [projects, setProjects] = useState(0);
  const [employees, setEmployees] = useState(0);

  useEffect(() => {
    const incrementValues = (setValue, target, delay) => {
      let count = 0;
      const interval = setInterval(() => {
        if (count < target) {
          count++;
          setValue(count);
        } else {
          clearInterval(interval);
        }
      }, delay);
    };

    incrementValues(setSatisfaction, 98, 80);
    incrementValues(setExperience, 12, 500);
    incrementValues(setProjects, 120, 60);
    incrementValues(setEmployees, 80, 100);
  }, []);

  const theme = document.body.getAttribute("data-theme");

  const containerClass =
    theme === "dark" ? "bg-dark text-white" : "bg-light text-dark";
  const headingClass = theme === "dark" ? "text-white" : "text-dark";
  const statClass = theme === "dark" ? "text-white" : "text-dark";

  return (
    <div className={`container-fluid py-5 ${containerClass}`}>
      <div className="container text-center">
        <h2 className={`mb-5 display-6 fw-bold ${headingClass}`}>
          Measuring Our Impact
        </h2>
        <div className="row">
          <div className="col-md-3">
            <h4 className={statClass}>Client Satisfactory Rate</h4>
            <h1 className={statClass}>{satisfaction}%</h1>
          </div>
          <div className="col-md-3">
            <h4 className={statClass}>Years of Experience</h4>
            <h1 className={statClass}>{experience}</h1>
          </div>
          <div className="col-md-3">
            <h4 className={statClass}>Projects Completed</h4>
            <h1 className={statClass}>{projects}</h1>
          </div>
          <div className="col-md-3">
            <h4 className={statClass}>Happy Employees</h4>
            <h1 className={statClass}>{employees}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurImpact;
