import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const ServiceCard = ({ id, title, description, image, className }) => {
  const [theme, setTheme] = useState(document.body.getAttribute("data-theme"));

  // Listen for theme changes and update the state
  useEffect(() => {
    const handleThemeChange = () => {
      setTheme(document.body.getAttribute("data-theme"));
    };

    // Listen for theme change event
    window.addEventListener("themeChange", handleThemeChange);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("themeChange", handleThemeChange);
    };
  }, []);

  // Apply classes based on the theme
  const cardClass =
    theme === "dark" ? "bg-dark text-light" : "bg-light text-dark";

  return (
    <div className={className}>
      <div className={`card h-100 d-flex flex-column shadow-lg ${cardClass}`}>
        <img
          src={image}
          alt={title}
          className="card-img-top"
          style={{
            width: "100%",
            height: "200px",
            objectFit: "cover",
            objectPosition: "top",
          }}
        />
        <div className="card-body d-flex flex-column">
          <h5 className="card-title">{id}</h5>
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{description}</p>
          <Link
            to={`/services/${title.toLowerCase().replace(/\s+/g, "-")}`}
            className={`btn btn-secondary text-light mt-auto`}
          >
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
