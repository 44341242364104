import { useState, useEffect } from "react";
import ReactTypingEffect from "react-typing-effect";
import { Link } from "react-router-dom";
import "./css/HeroCarousel.css";

const slides = [
  {
    image:
      "https://res.cloudinary.com/dawcadg7s/image/upload/v1736769932/omjgwmglm3skjf2cxi7j.jpg",
    alt: "Gender Inclusion",
    heading: "Leading Gender and Disability Mainstreaming in Kenya and Africa",
    description:
      "At GDDC Kenya, we are committed to championing the inclusion of gender and disability perspectives in all sectors of society. Our work focuses on empowering marginalized groups and transforming institutional frameworks to foster an inclusive and equitable future for all. Together, we can create an environment where everyone, regardless of gender or ability, has the opportunity to thrive.",
    link: { href: "/about", text: "Learn More About Our Mission" },
  },
  {
    image:
      "https://res.cloudinary.com/dawcadg7s/image/upload/v1736770387/kkn2fg8xnlz7lrpbnwb6.png",
    alt: "Training Officers",
    heading: "150,000+ Officers Trained in Gender and Disability Mainstreaming",
    description:
      "Our dedicated training programs have successfully equipped over 150,000 public and private sector officers with the knowledge and skills needed to integrate gender and disability considerations into their work. Through specialized workshops and tailored support, we help organizations develop more inclusive policies and practices, ensuring long-term impact for individuals and communities alike.",
    link: { href: "/services/training-and-workshops", text: "See Our Impact" },
  },
  {
    image:
      "https://res.cloudinary.com/dawcadg7s/image/upload/v1736769931/uvq8tb4vrmh3br8vlamu.jpg",
    alt: "Workshop Registration",
    heading: "Join Our Next Gender and Disability Mainstreaming Workshop",
    description:
      "Ready to make a difference? Our upcoming workshop offers a unique opportunity to learn from experienced trainers, exchange ideas with fellow advocates, and deepen your understanding of gender and disability mainstreaming. Whether you're part of an organization or a community leader, this is your chance to be part of a transformative journey that drives meaningful change in society.",
    link: { href: "/events", text: "Register for Upcoming Events" },
  },
  {
    image:
      "https://res.cloudinary.com/dawcadg7s/image/upload/v1736769931/ensrbad5vwbsyw4wl8dq.jpg",
    alt: "Award Ceremony",
    heading: "Kenya Equality and Inclusion Awards",
    description:
      "We celebrate individuals and organizations that champion equality and inclusion. This event recognizes outstanding achievements that inspire and drive transformative change in Kenya.",
    link: { href: "/awards", text: "Learn More About the Awards" },
  },
];

const HeroCarousel = () => {
  const [carouselIndex, setCarouselIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCarouselIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="p-0">
      <div
        id="header-carousel"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
        data-bs-interval="20000"
      >
        <div className="carousel-inner">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`carousel-item position-relative ${
                carouselIndex === index ? "active" : ""
              }`}
              style={{ height: "100vh" }}
            >
              <img
                className="position-absolute w-100 h-100"
                src={slide.image}
                alt={slide.alt}
                style={{
                  objectFit: "cover",
                  objectPosition: "top",
                }}
                loading="lazy"
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: "rgba(0, 0, 0, 0.5)",
                  zIndex: 0,
                }}
              ></div>
              <div
                className="carousel-caption d-flex flex-column align-items-center justify-content-center text-center"
                style={{
                  zIndex: 2,
                  minHeight: "100vh",
                }}
              >
                <div style={{ maxWidth: "1080px", padding: "1rem" }}>
                  <h4 className="display-5 fw-semibold text-white my-4 pt-5">
                    <ReactTypingEffect
                      text={[slide.heading]}
                      speed={150}
                      eraseSpeed={50}
                      typingDelay={200}
                      eraseDelay={3500}
                    />
                  </h4>
                  <h3 className="lead mb-4 text-white">{slide.description}</h3>
                  <Link
                    to={slide.link.href}
                    className="btn btn-secondary text-light"
                    style={{
                      fontSize: "1.1rem",
                      padding: "12px 25px",
                    }}
                    name={slide.link.text}
                  >
                    {slide.link.text}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Carousel Controls */}
        <a
          className="carousel-control-prev"
          href="#header-carousel"
          role="button"
          data-bs-slide="prev"
        >
          <div className="btn btn-lg btn-secondary btn-lg-square">
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
          </div>
        </a>
        <a
          className="carousel-control-next"
          href="#header-carousel"
          role="button"
          data-bs-slide="next"
        >
          <div className="btn btn-lg btn-secondary btn-lg-square">
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
          </div>
        </a>
      </div>
    </div>
  );
};

export default HeroCarousel;
